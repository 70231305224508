

body::-webkit-scrollbar {
  width: 7px;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: #14AA72;
  outline: 1px solid #14AA72;
  border-radius: 200px;
}
.mobile-nav-active .mobile-nav-toggle {
  display:block !important;
}
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 transparent;
}

.card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #0000001c;
  border: none;
  border-radius: 15px;
 padding: 30px;
}
.text-left {
  text-align: left;
}
.boxshadow-0{
  box-shadow: none;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
hr {
  border-top: 1px solid grey;
  width: 100%;
  margin: 0 auto;
  margin-top: 40px;
}

/* INNER PAGE HEADER */
section.tsd__details {
  padding: 0;
  position: relative;
  height: 340px;
  background-size: cover;
  background-repeat: no-repeat;
  top: 55px;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

section.tsd__details:after {
  content: "";
  position: absolute;
  left: 0;
  background: #00000061;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

section.tsd__details .page__title {
  position: relative;
  z-index: 9999;
  text-align: center;
}
section.tsd__details .page__title h2{
  color: #fff;
  position: relative;
  display: initial;
  padding-bottom: 5px;
}
section.tsd__details .page__title h2:after {
  content: "";
  position: absolute;
  right: 0;
  background: var(--color-primary);
  width: 100%;
  height: 8px;
  bottom: 0;
  border-radius: 100px;
}

section.tsd__details .page__title .icon {
  padding-bottom: 10px;
}

section.tsd__details .page__title .icon img {
  height: 55px;
}


/* CALENDAR DETAILS */
section.upcoming__events .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: transparent;
  border: 2px solid #14AA72;
  border-radius: 13px;
  opacity: 1;
  padding: 20px 30px;
}


section.upcoming__events .tab__grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

section.upcoming__events .title h2 {
    color: #2E2E2E;
    opacity: 1;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 6px;
}

section.upcoming__events .title p {
    color: #BFBFBF;
    opacity: 1;
    font-size: 14px;
    margin: 0;
    text-align: right;
}
section.upcoming__events .title {
  width: 70%;
}
section.upcoming__events .left__btn button.btn-time {
  background: #14AA72 0% 0% no-repeat padding-box;
  opacity: 1;
  border: 0;
  color: #fff;
  padding: 5px 20px;
  margin-left: 10px;
  border-radius: 3px;
  min-height: 37px;
}

section.upcoming__events .left__btn button.btn-arrow {
  background: #2E2E2E 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 5px #2E2E2E4D;
  border-radius: 3px;
  opacity: 1;
  padding: 3px 15px;
  border-radius: 3px;
  min-height: 37px;
}
section.upcoming__events .nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
  border: 2px solid transparent;
  border-radius: 13px;
  opacity: 1;
  padding: 20px 30px;
}

section.upcoming__events .tab__content .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;
}

section.upcoming__events .tab__content .date__time {
  display: flex;
  align-items: center;
}

section.upcoming__events .tab__content .date__time p {
  margin: 0;
  color: #14AA72;
  font-weight: 700;
  font-size: 22px;
  margin: 0 10px;
}

section.upcoming__events .tab__content .tab__content .header img {
  height: 51px;
}

section.upcoming__events .tab__content {
  border: 2px solid #14AA72;
  border-radius: 13px;
  opacity: 1;
  padding: 22px 40px;
  margin-bottom: 30px;
}

section.upcoming__events .tab__content .decription h4 {
  font-weight: bold;
}

section.upcoming__events .tab__content .decription p {
  letter-spacing: 0px;
  color: #BFBFBF;
  opacity: 1;
  margin: 0;
}
section.upcoming__events .tab__content .header img {
  height: 54px;
}

.trainingGrids{
  transition: all .1s ease-in;
  border-radius: 0px;
  border: 2px solid transparent !important;
  padding: 15px !important;
  border-bottom: 2px solid #eceff1 !important;
  padding-bottom: 30px !important;
 
}
.trainingGrids:last-child{
  border-bottom: 2px solid transparent !important;
}

.trainingGrids:hover{
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid #11AE21 !important;
  border-radius: 10px;
  /* cursor: pointer; */
  transition: opacity .10s ease-in-out;
  -moz-transition: opacity .10s ease-in-out;
  -webkit-transition: opacity .10s ease-in-out;
  transition: all ease-in-out 0.2s;
  padding: 15px !important;
  padding-bottom: 30px !important;
}

.homeCalendarSearch {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 130px;
  z-index: 1;
  top: auto;
}

button.btn.btn-calendarsearch {
  background: #14aa72;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: #fff;
}

.homeCalendarSearch .form-control {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
  width: 184px;
}
.homeCalendarSearch .form-control:focus{
  border: 1px solid #eceff1;
}
.calendar__select {
  position: absolute;
  left: 30px;
  z-index: 1;
}
.calendar__select select.form-select {
  border: none;
  font-size: 18px;
  color: #2e2e2e;
  padding-left: 0;
}
.form-select:focus{
  outline: none !important;
  box-shadow: none !important;
  border: none;
}

/* RESPONSVE */
button.navbar-toggler{
  border: none;
  color: var(--color-primary);
}
button.navbar-toggler:focus{
  border: none;
  outline: none;
  box-shadow: none;
}

/* LOGIN */

/* LOGIN */

.portal__login {
  padding: 15px;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  z-index: 1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.portal__login:before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  left: 0;
  top: 0;
  position: absolute;
  z-index: -1;
}
.portal__login .portal__content-between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.portal__login .portal__content {
  max-width: 660px;
  width: 100%;
  background-color: #fff;
  padding: 60px 90px;
  border-radius: 20px;
}
@media only screen and (max-width: 991px) {
  .portal__login .portal__content {
    padding: 90px 70px 70px;
  }
}
@media only screen and (max-width: 767px) {
  .portal__login .portal__content {
    padding: 70px 50px 50px;
  }
}
@media only screen and (max-width: 575px) {
  .portal__login .portal__content {
    padding: 50px 30px 30px;
  }
}
.portal__login .portal__header {
  background-color: transparent;
  margin-bottom: 30px;
  text-align: center;
}
.portal__login .portal__header img {
  height: 100px;
}
.portal__login .portal__header .portal__logo {
  display: block;
  max-width: 40vw;
}
.portal__login .portal__form {
  margin-bottom: 40px;
}
.portal__login .portal__form .portal__otp-logo {
  margin-bottom: 20px;
  display: block;
}
.portal__login .portal__form h2 {
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;
}
.portal__login .portal__form h2.title{
  font-weight: 200;
  margin-bottom: 15px;
  text-align: center;
  font-size: 22px;
  margin-top: 20px;
}
.portal__login .portal__form p {
  margin-bottom: 30px;
  font-size: 17px;
}
.portal__login .portal__form p span {
  display: block;
}
.portal__login .portal__form .portal__otp-label {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 500;
}
.portal__login .portal__form .form-group {
  position: relative;
  z-index: 1;
  margin-bottom: 15px;
}
.portal__login .portal__form .form-group i {
  position: absolute;
  z-index: 1;
  right: 5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.portal__login .portal__form .form-group [class^="flaticon-"]:before,
.portal__login .portal__form .form-group [class*=" flaticon-"]:before,
.portal__login .portal__form .form-group [class^="flaticon-"]:after,
.portal__login .portal__form .form-group [class*=" flaticon-"]:after {
  margin-left: 0;
  font-size: 17px;
  color: #a1a1a1;
}
.portal__login .portal__form .form-control {
  background-color: #f6f6f6;
  min-height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  padding: 10px 30px;
  color: #111111;
}
.portal__login .portal__form input::-webkit-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.portal__login .portal__form input::-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.portal__login .portal__form input:-moz-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.portal__login .portal__form input:-ms-input-placeholder {
  color: #a1a1a1;
  font-size: 15px;
  font-weight: 300;
}
.portal__login .portal__form .portal__form-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
}
.portal__login .portal__form .portal__form-row .portal__form-col {
  padding: 10px 5px;
  text-align: center;
  margin-right: 10px;
  flex-basis: 0;
  flex-grow: 1;
}
.portal__login .portal__form .portal__form-row .portal__form-col:last-child {
  margin-right: 0;
}
.portal__login .portal__form .portal__form-btn {
  margin-bottom: 10px;
}
.portal__login .portal__form .portal__resend-wrap {
  color: #999898;
  margin-bottom: 10px;
  display: inline-flex;
  flex-wrap: wrap;
}
.portal__login .portal__form .portal__btn-resend {
  margin-left: 3px;
  box-shadow: none;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  color: #63bbff;
  text-decoration: underline;
  transition: all 0.3s ease-in-out;
}
.portal__login .portal__form .portal__btn-resend:focus {
  outline: none;
}
.portal__login .portal__form .portal__btn-resend:hover {
  color: #000000;
}
.portal__login .portal__form .text-or {
  margin-left: 3px;
  color: #000000;
}
.portal__login .portal__btn-fill {
  margin-right: 30px;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 3px;
  background-color: #ff5e13;
  padding: 10px 36px;
  margin-bottom: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.portal__login .portal__btn-fill:hover {
  background-color: #f0530b;
  border-color: #f0530b;
}
.portal__login .portal__btn-fill:focus {
  outline: none;
}
.portal__login .switcher-text {
  color: #9f9f9f;
  font-size: 15px;
  margin-bottom: 7px;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.portal__login .switcher-text:hover {
  color: #666;
}
.portal__login .switcher-text2 {
  color: #63bbff;
  font-size: 15px;
  display: inline-block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.portal__login .switcher-text2.inline-text {
  margin-left: 3px;
}
.portal__login .switcher-text2:hover {
  color: #000;
}
.portal__login ul.portal__socials {
  margin-right: 15px;
  margin-bottom: 15px;
}
.portal__login ul.portal__socials li {
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 575px) {
  .portal__login ul.portal__socials li {
    margin-right: 2px;
  }
}
.portal__login ul.portal__socials li:last-child {
  margin-right: 0;
}
.portal__login ul.portal__socials li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  height: 40px;
  width: 40px;
  color: #ffffff;
  border-radius: 50%;
  border: 1px solid;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.portal__login ul.portal__socials li.portal__facebook a {
  background-color: #3b5998;
  border-color: #3b5998;
}
.portal__login ul.portal__socials li.portal__facebook a:hover {
  background-color: transparent;
  color: #3b5998;
}
.portal__login ul.portal__socials li.portal__twitter a {
  background-color: #00acee;
  border-color: #00acee;
}
.portal__login ul.portal__socials li.portal__twitter a:hover {
  background-color: transparent;
  color: #00acee;
}
.portal__login ul.portal__socials li.portal__google a {
  background-color: #CC3333;
  border-color: #CC3333;
}
.portal__login ul.portal__socials li.portal__google a:hover {
  background-color: transparent;
  color: #CC3333;
}
.portal__login ul.portal__socials li.portal__instagram a {
  background-color: #3f729b;
  border-color: #3f729b;
}
.portal__login ul.portal__socials li.portal__instagram a:hover {
  background-color: transparent;
  color: #3f729b;
}
.portal__login ul.portal__socials li.portal__linkedin a {
  background-color: #0077B5;
  border-color: #0077B5;
}
.portal__login ul.portal__socials li.portal__linkedin a:hover {
  background-color: transparent;
  color: #0077B5;
}
.portal__login ul.portal__socials li.portal__youtube a {
  background-color: #c4302b;
  border-color: #c4302b;
}
.portal__login ul.portal__socials li.portal__youtube a:hover {
  background-color: transparent;
  color: #c4302b;
}
.portal__login .portal__footer {
  background-color: #fff;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.portal__login .portal__footer p {
  color: #747474;
}
.Notfound {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dummyImageStyle {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.imageMargin {
  margin: 10px;
}

.imageWidth150 {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.mousePointer {
  cursor: pointer;
}

/* 29-01-2023 */
span.not-count {
  width: 20px;
  height: 20px;
  background: #f1be2e !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  color: #FFF;
  font-size: 11px;
  position: absolute;
  left: 0px;
  top: 5px;
  font-weight: 600;
}
.mr-2{
  margin-right: 10px;
}
p.mb-0.mr-2 {
    margin-right: 6px;
}
.css-tr4s17-option {
  background-color: #14aa72 !important;
  color: #000 !important;
}
.css-10wo9uf-option{
  color: #000 !important;
}
.css-d7l1ni-option {
  background-color: #f3fdf9 !important;
  color: #000 !important;
}
.default__heading .css-1dimb5e-singleValue {
  font-weight: bold !important;
}
button.btn.btn-search.formClear {
  padding: 18px 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.btn.btn-search.formClear i {
  display: flex;
  align-items: center;
  justify-content: center;
}



.marquee {
  height: 25px;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.marquee div {
  display: block;
  width: 200%;
  height: 30px;
  position: absolute;
  overflow: hidden;
  animation: marquee 15s linear infinite;
}

.marquee span {
  float: left;
  width: 50%;
  color: #fff;
}
.marquee span a{
  color: #fff;
}

@keyframes marquee {
  0% { left: 0; }
  100% { left: -100%; }
}

.imoji__feedback .btn.btn-outline-primary {
  padding: 0;
  border: none;
  border-radius: 100px;
}
.imoji__feedback .btn-check:focus{
  box-shadow: none !important;
  outline: none !important;
  border: none;
  background-color: transparent !important;
}

.imoji__feedback {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.imoji__feedback img {
  height: 90px;
}
.imoji__feedback img:hover{
  height: 90px;
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
  border: var(--color-primary) !important;
}
.feedback-modal .modal-header{
  text-align: center;
  justify-content: center;
}
.feedback-modal label{
  border-radius: 100px;
}
/* ve11adan */
.feedback-modal label:checked.btn-check:active{
  border-radius: 100px;
  background: var(--color-primary) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.feedback-modal .btn.btn-outline-primary:hover{
  border-radius: 100px;
  background: var(--color-secondary) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.initiativeHomeImg img {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 10px;
}

.initiativeHomeImg {
  width: 100%;
  padding-left: 20px;
}

.cursor-pointer{
  cursor: pointer;
}

/* ADVACNED SEARCH */
.rmsc .dropdown-container {
  padding: 4px 20px !important;
  border-radius: 8px !important;
}
.rmsc .options {
  padding-left: inherit !important;
  padding-right: 0 !important;
}
.rmsc .item-renderer {
  color: black !important;
}
.rmsc .item-renderer input {
  margin: 0px 0px 0 5px !important;
}
.rmsc .search-clear-button {
  right: auto !important;
  left: 0 !important;
}
.rmsc .dropdown-content {
  right: 0;
}
.rmsc .dropdown-container:focus-within {
  box-shadow: #14aa72 0 0 0 1px !important;
  border-color: #14aa72 !important; 
}
/* .rmsc .dropdown-content .panel-content {
  height: 170px;
  overflow-y: auto !important;
} */

.rmsc .options {
  max-height: 131px !important;
}


.rmsc .options::-webkit-scrollbar {
  width: 7px;
}
.rmsc .options::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
.rmsc .options::-webkit-scrollbar-thumb {
  background-color: #14AA72;
  outline: 1px solid #14AA72;
  border-radius: 200px;
} 



.rmsc .dropdown-heading .dropdown-heading-value{
  color: #aaa !important;
}

button.btn.btn-secondary:hover {
  background-color:#000 !important;
  border-color: #000 !important;
}
.initiativeModal .modal-content img {
  height: 300px;
  object-fit: contain;
  margin-top: 30px;
  width: 100%;
}

.initiativeModal .modal-body h2 {
  font-size: 16px;
  color: var(--color-primary);
  text-transform: uppercase;
  font-weight: 600;
}

.viewCalendar_desc{
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 20px;
}
