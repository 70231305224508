/* jodit */

.jodit-container *:not(a, a *) {
    background-color: inherit !important;
    font-family: var(--font-primary) !important;
    line-height: inherit !important;
}

.jodit-container p,
.jodit-container h2 {
    padding: 0 !important;
}

.jodit-container ul {
    list-style-type: disc !important;
    margin: 0 !important;
}

.jodit-container li {
    margin: 0 !important;
}

.jodit-container a {
    pointer-events: none;
}

.jodit-container span {
    cursor: text;
}
img.slick-arrow.slick-disabled {
    opacity: 0 !important;
}